<template>
    <el-container class="page-container">
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->

        <!--</el-header>-->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-position='left' label-width="80px" :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-input placeholder="请输入内容" v-model="query.searchKeyword">
                            <el-select slot="prepend" v-model="query.searchName" placeholder="查询类型" style="width: 120px;">
                                <el-option label="用户ID" value="userId"></el-option>
                                <el-option label="收货人姓名" value="userName"></el-option>
                                <el-option label="联系电话" value="mobile"></el-option>
                                <el-option label="性别：1：男，2：女  默认1" value="sex"></el-option>
                                <!--<el-option label="省" value="provinceName"></el-option>-->
                                <!--<el-option label="市" value="cityName"></el-option>-->
                                <!--<el-option label="区" value="countyName"></el-option>-->
                                <el-option label="街道" value="streetName"></el-option>
                                <el-option label="详细地址" value="address"></el-option>
                                <el-option label="默认收货地址： 0-否，1-是  默认0" value="isDefault"></el-option>
                                <!--<el-option label="商家ID" value="shopId"></el-option>-->
                                <el-option label="标签 ：公司，家，学校" value="tag"></el-option>
                            </el-select>
                        </el-input>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

            <el-table
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        prop="userId"
                        label="用户ID"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="userName"
                        label="收货人姓名"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="mobile"
                        label="联系电话"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>

                <el-table-column
                        prop="sex"
                        label="性别"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.sex==1">男</span>
                        <span v-if="scope.row.sex==2">女</span>
                    </template>
                </el-table-column>
                <!--<el-table-column-->
                        <!--prop="provinceName"-->
                        <!--label="省"-->
                        <!--:show-overflow-tooltip="true"-->
                <!--&gt;-->
                <!--</el-table-column>-->
                <!--<el-table-column-->
                        <!--prop="cityName"-->
                        <!--label="市"-->
                        <!--:show-overflow-tooltip="true"-->
                <!--&gt;-->
                <!--</el-table-column>-->
                <!--<el-table-column-->
                        <!--prop="countyName"-->
                        <!--label="区"-->
                        <!--:show-overflow-tooltip="true"-->
                <!--&gt;-->
                <!--</el-table-column>-->
                <el-table-column
                        prop="streetName"
                        label="街道"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="详细地址"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="isDefault"
                        label="默认收货地址"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.isDefault==0">否</span>
                        <span v-if="scope.row.isDefault==1">是</span>
                    </template>
                </el-table-column>
                <!--<el-table-column-->
                        <!--prop="shopId"-->
                        <!--label="商家ID"-->
                        <!--:show-overflow-tooltip="true"-->
                <!--&gt;-->
                <!--</el-table-column>-->
                <el-table-column
                        prop="tag"
                        label="标签"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
<!--                <el-table-column-->
<!--                        fixed="right"-->
<!--                        width="120"-->
<!--                        label="操作">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button size="mini"-->
<!--                                   :type="scope.row.payStatus==0?'primary':''"-->
<!--                                   :disabled="scope.row.payStatus!=0"-->
<!--                                   @click="handleChangeOpenPassword(scope.row)">线下收款-->
<!--                        </el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>

    </el-container>

</template>

<script>
  import {mapGetters} from 'vuex'
  import {addressData} from '../../api/orderManage'
  export default {
    name: "orderRecord",
    data() {
      return {
        baseUrl: process.env.VUE_APP_BASEAPI + 'fastdfs/uploadFile',
        tableData: [],
        pageInfo: {
          totalCount: 0,
          pageNumber: 1,
          pageSize: 10,
          pageOrderName:'opAt',
          pageOrderBy:'descending',
        },
        query: {
          searchName: '',
          searchKeyword: ''
        },
      }
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),
    },
    methods: {
      handleSearch() {
        this.pageInfo.pageNumber = 1
        this.addressData()
      },
      async addressData() {
        try {
          let query = Object.assign({}, this.pageInfo, this.query)
          let formData = {}
          for (let i in query) {
            if (query[i] !== '' && i !== 'total' && query[i] !== -1) {
              formData[i] = query[i]
            }
          }
          let resonse = await addressData(formData)
          let {data} = resonse;
          this.tableData = data.list;
          this.pageInfo.totalCount = data.totalCount
        } catch (e) {
          console.log(e)
        }
      },
      clearSearch() {
        this.query={
          searchName: '',
          searchKeyword: ''
        };
        this.handleSearch()
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.pageInfo.pageNumber = 1
        this.addressData()
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNumber = val
        this.addressData()
        console.log(`当前页: ${val}`);
      },
      //线下收款
      handleChangeOpenPassword(row) {
        console.log(254,row)
        this.$confirm('确认已收款?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            await docollect(row.id);
            this.$message({
              type: 'success',
              message: '更新成功!'
            });
            this.addressData()
          }catch (e) {
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
    },
    mounted() {
      this.addressData()
    }
  }
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }

    }
    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }
    .el-input__prefix{
        left: 15px;
        color: #303133;
        i{
            font-style:normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }


</style>
